import classes from './QuoteSelection.module.css'

import johnnyDepp from '../assets/frontShow/Johnny Depp.png'
import kofiAnnan from '../assets/frontShow/Kofi Annan.png'
import mahatmaGandhi from '../assets/frontShow/Mahatma Gandhi.png'
import mayaAngelou from '../assets/frontShow/Maya Angelou.png'
import scottHamilton from '../assets/frontShow/Scott Hamilton.png'
import vernaMyers from '../assets/frontShow/Verna Myers.png'

function QuoteSelection({ translations, setUserChoice, userChoice, isHe }) {
  const choseQuote = (quote) => {
    setUserChoice((prevState) => {
      return {
        ...prevState,
        quote: quote,
      }
    })
  }

  return (
    <div dir={isHe ? 'rtl' : 'ltr'} className={classes.main}>
      <div className={classes.quote_area}>
        <h3>{translations.quoteSelection}</h3>
        <div className={classes.quote_grid} dir="ltr">
          <img
            className={`${classes.img_cuote} ${
              userChoice.quote === 'Maya Angelou' ? classes.chosen : ''
            }`}
            src={mayaAngelou}
            alt="img"
            onClick={() => {
              choseQuote('Maya Angelou')
            }}
          />
          <img
            className={`${classes.img_cuote} ${
              userChoice.quote === 'Kofi Annan' ? classes.chosen : ''
            }`}
            src={kofiAnnan}
            alt="img"
            onClick={() => {
              choseQuote('Kofi Annan')
            }}
          />

          <img
            className={`${classes.img_cuote} ${
              userChoice.quote === 'Johnny Depp' ? classes.chosen : ''
            }`}
            src={johnnyDepp}
            alt="img"
            onClick={() => {
              choseQuote('Johnny Depp')
            }}
          />

          <img
            className={`${classes.img_cuote} ${
              userChoice.quote === 'Mahatma Gandhi' ? classes.chosen : ''
            }`}
            src={mahatmaGandhi}
            alt="img"
            onClick={() => {
              choseQuote('Mahatma Gandhi')
            }}
          />

          <img
            className={`${classes.img_cuote} ${
              userChoice.quote === 'Verna Myers' ? classes.chosen : ''
            }`}
            src={vernaMyers}
            alt="img"
            onClick={() => {
              choseQuote('Verna Myers')
            }}
          />

          <img
            className={`${classes.img_cuote} ${
              userChoice.quote === 'Scott Hamilton' ? classes.chosen : ''
            }`}
            src={scottHamilton}
            alt="img"
            onClick={() => {
              choseQuote('Scott Hamilton')
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default QuoteSelection
