import { useRef } from 'react'
import classes from './FrameSelection.module.css'
import v from '../assets/v.png'

function FrameSelection({
  translations,
  setUserChoice,
  userChoice,
  clickedOkToInput,
  isHe,
  scrollPageDown,
}) {
  const nameRef = useRef()
  const titleRef = useRef()

  const choseFrame = (quoteNum) => {
    setUserChoice((prevState) => {
      return {
        ...prevState,
        frame: quoteNum,
      }
    })

    scrollPageDown('four_part')
  }

  const clickedOk = () => {
    const name = nameRef.current.value
    const title = titleRef.current.value

    if (name !== '' && title !== '') {
      setUserChoice((prevState) => {
        return {
          ...prevState,
          name,
          title,
        }
      })
      clickedOkToInput()
    }
  }

  const handleInputChange = (event) => {
    const name = event.target.value
    const title = event.target.value

    setUserChoice((prevState) => {
      return {
        ...prevState,
        name,
        title,
      }
    })
  }

  return (
    <div dir={isHe ? 'rtl' : 'ltr'} className={classes.main}>
      <div className={classes.frame_area}>
        <h3>{translations.frameSelection}</h3>
        <div className={classes.frame_grid}>
          <div
            className={`${classes.frame} ${classes.frame_1} ${
              userChoice.frame === 1 ? classes.chosen : ''
            }`}
            onClick={() => {
              choseFrame(1)
            }}
          ></div>
          <div
            className={`${classes.frame} ${classes.frame_2} ${
              userChoice.frame === 2 ? classes.chosen : ''
            }`}
            onClick={() => {
              choseFrame(2)
            }}
          ></div>
          <div
            className={`${classes.frame} ${classes.frame_3} ${
              userChoice.frame === 3 ? classes.chosen : ''
            }`}
            onClick={() => {
              choseFrame(3)
            }}
          ></div>
          <div
            className={`${classes.frame} ${classes.frame_4} ${
              userChoice.frame === 4 ? classes.chosen : ''
            }`}
            onClick={() => {
              choseFrame(4)
            }}
          ></div>
          <div
            className={`${classes.frame} ${classes.frame_5} ${
              userChoice.frame === 5 ? classes.chosen : ''
            }`}
            onClick={() => {
              choseFrame(5)
            }}
          ></div>
          <div
            className={`${classes.frame} ${classes.frame_6} ${
              userChoice.frame === 6 ? classes.chosen : ''
            }`}
            onClick={() => {
              choseFrame(6)
            }}
          ></div>
          <div
            className={`${classes.frame} ${classes.frame_7} ${
              userChoice.frame === 7 ? classes.chosen : ''
            }`}
            onClick={() => {
              choseFrame(7)
            }}
          ></div>
          <div
            className={`${classes.frame} ${classes.frame_8} ${
              userChoice.frame === 8 ? classes.chosen : ''
            }`}
            onClick={() => {
              choseFrame(8)
            }}
          ></div>
        </div>
      </div>
      <div className={classes.input_area}>
        <div className={classes.btnArea}>
          <div className={classes.inputs}>
            <div>
              <label htmlFor="name">{translations.name}</label>
              <input
                type="text"
                maxLength={22}
                ref={nameRef}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label htmlFor="title">{translations.title}</label>
              <input
                type="text"
                ref={titleRef}
                maxLength={22}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <img onClick={clickedOk} className={classes.v} src={v} alt="v" />
        </div>
      </div>
    </div>
  )
}

export default FrameSelection
