const eng = {
  openingP: `We all have an identity that accompanies us throughout the years. We live in day-to-day activities.
  Today, we say that our uniqueness, our story,
  This is our Imprint!`,
  secondP: ``,
  sedondBox: `HP Indigo invites everyone to open their hearts and listen to the unique stories around them.`,
  sedondBox2: `Your life story encompasses your life experience, your personal perspective, and your professional expertise.
  `,
  sedondBox3: `Together, all of your individual stories create the unique narrative of HP Indigo.
  `,
  thirdBox: `We will all change our social media profile pictures to a unique image to raise awareness of diversity, equality, and inclusion in the organization.`,
  thirdBox2: `To participate in the campaign and create a new profile picture for yourself, please agree to Terms and Conditions, and follow the instructions below.`,
  thirdBox3: `We encourage you to share your new profile picture on social media and use the hashtag #OWNYOURSTORY.`,
  checkbox: 'By clicking OK, I authorize the use of the site',
  quoteSelection: `FIRST, CHOOSE THE QUOTE YOU'RE MOST CONNECTED TO`,
  frameSelection: 'Choose a color',
  name: 'Your name here',
  title: 'Your job title here',
  ok: 'ok',
  uploadBtnH3: 'Click to upload your photo',
  errorSelectImage: 'Please select a valid image file.',
  errorImage: 'Please select an image file.',
  soooWowDid: 'Sooo how did we do?',
  tryAgain: `I'LL TRY AGAIN`,
  LooksAwesome: `LOOKS AWESOME!`,
  yourName: 'Type your name',
  policy: `I agree that any personal data about me that I provide or that will be collected during the course of my use of the website, shall be collected and retained by HP Indigo Ltd., for the purpose of facilitating the services offered through the website or any other legitimate business purposes. I acknowledge that I am under no legal obligation to provide personal data and I am doing so with my own free will and consent. The personal data may be transferred to service providers (in Israel or outside of Israel, including to countries which may provide a different level of data protection) and/or to HP group of companies in Israel, for the purposes detailed above.`,
  ifNoDownload: `Please click here if the download didn't start`
}

export default eng
