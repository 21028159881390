import React, { useState, useEffect } from 'react'

const ErrorNotification = ({
  setOpenError,
  errorMessage = 'An error occurred, please try again later',
  duration = 3000,
  backgroundColor = 'red'
}) => {
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false)
      setOpenError(false)
    }, duration)

    return () => clearTimeout(timer)
  }, [duration, setOpenError])

  return visible ? (
    <div
      style={{
        position: 'fixed',
        top: '80px',
        fontSize: '1.5rem',
        right: '20px',
        padding: '10px',
        backgroundColor: backgroundColor,
        color: 'white',
        borderRadius: '5px',
        display: errorMessage ? 'block' : 'none',
        zIndex: '20',
      }}
    >
      {errorMessage}
    </div>
  ) : null
}

export default ErrorNotification
