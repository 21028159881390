import { useEffect } from 'react'

function ImageProcessing({
  imageFile,
  setCanvas,
  setDownloadLinkVisible,
  userChoice,
  setOpenSpinner,
  setOriginalImage,
  // dataToCrap,
}) {
  useEffect(() => {
    if (imageFile) processImg(imageFile)
  }, [imageFile])

  const arrayBufferToBase64 = (buffer) => {
    let binary = ''
    const bytes = new Uint8Array(buffer)
    const len = bytes.byteLength

    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i])
    }

    return btoa(binary)
  }

  const loadBackground = async () => {
    try {
      const backgroundImage = await import(
        `../assets/bg/0${userChoice.frame}.png`
      )

      const frontImage = await import(
        `../assets/front/${userChoice.quote} ${userChoice.frame}.png`
      )

      return [backgroundImage.default, frontImage.default]
    } catch (error) {
      console.error('Error loading background image:', error)
    }
  }

  const processImg = async (imageFile) => {
    const images = await loadBackground()
    const base64Image = arrayBufferToBase64(imageFile)
    setOriginalImage({
      imageFile: imageFile,
      images: images,
      base64Image: base64Image,
    })

    const imageDataUrl = `data:image/png;base64,${base64Image}`

    const originalImage = new Image()

    originalImage.src = imageDataUrl

    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    originalImage.onload = function () {
      // זה מה שאני צריך אם אני עושה חיתוך של התמונה
      // const scaleFactor = dataToCrap.range
      // const left = dataToCrap.distanceLeft
      // const top = dataToCrap.distanceTop

      // const canvas = document.createElement('canvas')
      // const context = canvas.getContext('2d')

      // const squareWidth = 500 / scaleFactor
      // const squareHeight = 500 / scaleFactor
      // canvas.width = squareWidth
      // canvas.height = squareHeight

      // const sourceX = left / scaleFactor
      // const sourceY = top / scaleFactor
      // const sourceWidth = squareWidth
      // const sourceHeight = squareHeight

      // // Specify the destination rectangle on the canvas
      // const destX = 0
      // const destY = 0
      // const destWidth = squareWidth
      // const destHeight = squareHeight

      // context.drawImage(
      //   originalImage, // The original image
      //   sourceX, // X coordinate from where to start cutting
      //   sourceY, // Y coordinate from where to start cutting
      //   sourceWidth, // Width of the portion to cut
      //   sourceHeight, // Height of the portion to cut
      //   destX, // X coordinate on the canvas to draw the top-left corner
      //   destY, // Y coordinate on the canvas to draw the top-left corner
      //   destWidth, // Width of the drawn portion on the canvas
      //   destHeight // Height of the drawn portion on the canvas
      // )

      // .......................
      // זה מה שאני צריך אם אני לא עושה חיתוך של התמונה
      // canvas.width = originalImage.width
      // canvas.height = originalImage.height
      canvas.width = 2000
      canvas.height = 2000
      context.drawImage(originalImage, 0, 0)
      // ........................

      // Convert the image to black and white
      const imageData = context.getImageData(0, 0, canvas.width, canvas.height)
      const data = imageData.data

      for (let i = 0; i < data.length; i += 4) {
        const grayscale =
          data[i] * 0.299 + data[i + 1] * 0.587 + data[i + 2] * 0.114
        data[i] = grayscale
        data[i + 1] = grayscale
        data[i + 2] = grayscale
      }

      context.putImageData(imageData, 0, 0)

      // Add background image
      const bg = new Image()

      bg.src = images[0]
      bg.onload = function () {
        context.globalCompositeOperation = 'destination-over'
        context.drawImage(bg, 0, 0, canvas.width, canvas.height)

        // Add front banner image
        const front = new Image()
        front.src = images[1]
        front.onload = function () {
          context.globalCompositeOperation = 'source-over'
          context.drawImage(front, 0, 0, canvas.width, canvas.height)

          // Add text on the middle right side
          context.font = 'bold 90px englishRegular'
          context.fillStyle = 'black'
          context.fillText(
            `${userChoice.name}`,
            canvas.width * 0.53,
            canvas.height * 0.64
          )
          context.font = '90px englishRegular'
          context.fillText(
            `${userChoice.title}`,
            canvas.width * 0.53,
            canvas.height * 0.69
          )
          setCanvas(canvas)
          setDownloadLinkVisible(true)
          setOpenSpinner(false)
        }
      }
    }
  }

  return
}

export default ImageProcessing
