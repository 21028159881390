import { useState, useEffect, useRef } from 'react'
import { useApi } from '../customHooks/api'

import classes from './AutocompleteInput.module.css'

const AutocompleteInput = ({
  translations,
  setShowOk,
  setSelectedPerson,
  inputValue,
  setInputValue,
}) => {
  const [suggestions, setSuggestions] = useState([])
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const [timeoutId, setTimeoutId] = useState(false)
  const inputRef = useRef(null)

  const { getAll } = useApi()

  const handleInputChange = (e) => {
    const value = e.target.value
    setInputValue(value)
    clearTimeout(timeoutId)

    const timeoutIdTemp = setTimeout(() => {
      fetchSuggestions(value)
    }, 200)
    setTimeoutId(timeoutIdTemp)
  }

  const fetchSuggestions = async (query = '') => {
    try {
      getAll(`suggestions?q=${query}`)
        .then((response) => {
          setSuggestions(response.data)
        })
        .catch((error) => {
          console.log(error)
        })
    } catch (error) {
      console.error('Error fetching suggestions:', error)
    }
  }

  const handleInputClick = () => {
    setIsDropdownVisible(true)
  }

  const handleOutsideClick = (e) => {
    const isInsideList =
      e.target.tagName.toLowerCase() === 'li' ||
      e.target.parentElement.tagName.toLowerCase() === 'li'

    if (
      !isInsideList &&
      inputRef.current &&
      !inputRef.current.contains(e.target)
    ) {
      setIsDropdownVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  const handleItemClick = (selectedItem) => {
    setInputValue(
      `${selectedItem.firstNameEng ? selectedItem.firstNameEng : ''} ${
        selectedItem.lastNameEng ? selectedItem.lastNameEng : ''
      } ${selectedItem.firstNameHeb ? selectedItem.firstNameHeb : ''} ${
        selectedItem.lastNameHeb ? selectedItem.lastNameHeb : ''
      }`
    )

    setSelectedPerson(selectedItem)
    setShowOk(true)
    setIsDropdownVisible(false)
  }

  return (
    <div>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onClick={handleInputClick}
        placeholder={`${translations.yourName}...`}
        className={classes.first}
        ref={inputRef}
      />
      {isDropdownVisible && (
        <ul className={classes.dropdownList}>
          {suggestions.map((item) => (
            <li key={item._id} onClick={() => handleItemClick(item)}>
              {`${item.firstNameEng ? item.firstNameEng : ''} ${
                item.lastNameEng ? item.lastNameEng : ''
              } ${item.firstNameHeb ? item.firstNameHeb : ''} ${
                item.lastNameHeb ? item.lastNameHeb : ''
              }`}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default AutocompleteInput
