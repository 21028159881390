import { useState } from 'react'
import { useApi } from '../../customHooks/api'
import AutocompleteInput from '../AutocompleteInput'
import classes from './Policy.module.css'
import ErrorNotification from './ErrorNotification '
import v from '../../assets/v.png'
import x from '../../assets/x.png'

function Policy({ setOpenPopuph, clickedCheck, translations, isHe }) {
  const [showOk, setShowOk] = useState(false)
  const [selectedPerson, setSelectedPerson] = useState({})
  const [openError, setOpenError] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const { updateOne } = useApi()

  const handlerClick = () => {
    const name = `${
      selectedPerson.firstNameEng ? selectedPerson.firstNameEng : ''
    } ${selectedPerson.lastNameEng ? selectedPerson.lastNameEng : ''} ${
      selectedPerson.firstNameHeb ? selectedPerson.firstNameHeb : ''
    } ${selectedPerson.lastNameHeb ? selectedPerson.lastNameHeb : ''}`

    if (inputValue.trim().length === 0 || name !== inputValue) return

    const personId = selectedPerson._id

    updateOne('suggestions', personId, {})
      .then((response) => {
        clickedCheck(true)
      })
      .catch((error) => {
        setOpenError(true)
      })
  }

  return (
    <>
      {openError && (
        <ErrorNotification
          setOpenError={setOpenError}
          errorMessage={`The employee's name must be selected`}
        />
      )}
      <div
        className={`${classes.policy} ${
          isHe ? classes.hebrew_font : classes.english_font
        }`}
        dir={isHe ? 'rtl' : 'ltr'}
      >
        <div
        className={classes.x}
          onClick={() => {
            setOpenPopuph(false)
          }}
        >
          <img src={x} alt="X" />
        </div>
        <div className={classes.window}>{translations.policy}</div>
        <div className={classes.names}>
          <AutocompleteInput
            translations={translations}
            setShowOk={setShowOk}
            setSelectedPerson={setSelectedPerson}
            inputValue={inputValue}
            setInputValue={setInputValue}
          />
          {showOk && (
            <img
              src={v}
              alt="v"
              className={classes.vImg}
              onClick={handlerClick}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default Policy
