import classes from './bg.module.css'

function Bg({setOpenPopuph}) {
  return (
    <div
      className={classes.bg}
      onClick={() => {
        setOpenPopuph(false)
      }}
    ></div>
  )
}

export default Bg
