import classes from './LanguageButton.module.css'

function LanguageButton({ setIsHe, isHe }) {
  const setBtn = () => {
    setIsHe(!isHe)
  }

  return (
    <>
      {!isHe && (
        <button
          className={`${classes.button}  ${
            !isHe ? classes.hebrew_font : classes.english_font
          }`}
          onClick={setBtn}
        >
          עברית
        </button>
      )}
      {isHe && (
        <button className={classes.button} onClick={setBtn}>
          ENG
        </button>
      )}
    </>
  )
}

export default LanguageButton
