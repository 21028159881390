const he = {
  openingP: `לכולנו יש זהות שמלווה אותנו לאורך שנים. הייחוד שלנו הוא מגוון ההחלטות שעשינו, החיים שבנינו והחותם אותו אנחנו משאירים בעשייה היום יומית.`,
  secondP: `אנחנו בHP מאמינים 
  שהייחוד של כל אחד ואחת, 
  הוא החותם האישי 
  היוצר את הסיפור שלנו.`,
  sedondBox: `HP קוראת לכל אנשיה, לשתף את הסיפור הייחודי של כל אחד ואחת. 
  בסיפור האישי טבועים ניסיון החיים, המבט האישי והניסיון המקצועי.`,
  sedondBox2: `כל הסיפורים האישיים יחד, יוצרים את הסיפור של HP.`,
  sedondBox3: '',
  thirdBox: `ובכדי להעלות את המודעות לשונה ולמחבר בארגון, כולנו נחליף את תמונות הפרופיל ברשתות החברתיות לתמונה ייחודית.
  ונתייג
   #OWNYOURSTORY`,
  thirdBox2: `בכדי לקחת חלק בקמפיין וליצור לעצמכם תמונת פרופיל חדשה לרשתות החברתיות, אשרו את השימוש באתר והתקדמו על פי ההוראות.`,
  thirdBox3: ``,
  checkbox: 'בלחיצה על אישור, אני מאשר את השימוש באתר',
  quoteSelection: 'קודם כל, בוחרים את הציטוט אליו הכי התחברתם',
  frameSelection: 'אנא ביחרו בצבע אחד מתוך המגוון',
  name: 'פה רושמים שם',
  title: 'פה רושמים תפקיד',
  ok: 'אישור',
  uploadBtnH3: 'ליחצו בכדי להעלות תמונה ',
  errorSelectImage: 'אנא בחר קובץ תמונה חוקי.',
  errorImage: 'אנא בחר קובץ תמונה.',
  soooWowDid: 'איך יצא?',
  tryAgain: 'אנסה שוב',
  LooksAwesome: `איזה יופי!`,
  yourName: 'הכנס שם',
  policy: `אני מסכים/ה כי מידע אישי אודותיי שאמסור או שייאסף במסגרת השימוש שלי באתר, ייאסף ויישמר על ידי חברת אייץ. פי אינדיגו בע"מ, למטרת הצעת השירותים דרך האתר ולכל מטרה עסקית לגיטימית אחרת. ידוע לי כי לא מוטלת עליי כל חובה חוקית למסוק את המידע האישי ואני עושה זאת מרצוני החופשי ובהסכמתי. המידע האישי עשוי להיות מועבר לנותני שירותים (בישראל או מחוצה לה, כולל למדינות בהן רמת ההגנה על מידע אישי שונה מזו הנהוגה בישראל) ו/או לחברות בקבוצת HP בישראל, למטרות המפורטות לעיל.`,
  ifNoDownload: 'ניתן ללחוץ כאן במידה וההורדה לא התחילה',
}

export default he
