import axiosInstance from '../axios'

export const useApi = () => {
  const fetchData = async (url, method = 'GET', data = null) => {
    try {
      const response = await axiosInstance({
        method,
        url: `/api/v1/${url}`,
        data,
      })
      return response.data
    } catch (error) {
      console.error('API error:', error)
      throw error
    }
  }

  const getAll = (resource) => {
    return fetchData(resource)
  }

  const getOne = (resource, id) => {
    return fetchData(`${resource}/${id}`)
  }

  const createOne = (resource, data) => {
    return fetchData(resource, 'POST', data)
  }

  const updateOne = (resource, id, data) => {
    return fetchData(`${resource}/${id}`, 'PATCH', data)
  }

  const deleteOne = (resource, id) => {
    return fetchData(`${resource}/${id}`, 'DELETE')
  }

  return { getAll, getOne, createOne, updateOne, deleteOne }
}
