import { useState, useEffect } from 'react'
import { useApi } from '../customHooks/api'
import ImageProcessing from './ImageProcessing'
import Cropper from 'react-easy-crop'
import axiosInstance from '../axios'

import uploadImg from '../assets/button_upload.png'
import classes from './ClickUpload.module.css'
import ErrorNotification from '../component/popUp/ErrorNotification '
import BeatLoader from 'react-spinners/BeatLoader'
import v from '../assets/v.png'

import Draggable from 'react-draggable'

function ClickUpload({
  translations,
  setCanvas,
  setDownloadLinkVisible,
  userChoice,
  setOriginalImage
}) {
  const [errorMessage, setErrorMessage] = useState('')
  const [timeoutId, setTimeoutId] = useState()
  const [selectedImage, setSelectedImage] = useState(null)
  const [openSpinner, setOpenSpinner] = useState(false)
  const [openError, setOpenError] = useState(false)

  const { getAll } = useApi()

  // const [selectedImageUrl, setSelectedImageUrl] = useState(null)
  // const [originalWidth, setOriginalWidth] = useState()
  // const [imgWidth, setImgWidth] = useState()
  // const [finishCrap, setFinishCrap] = useState(false)
  // const [range, setRange] = useState(4)
  // const [isMobile, setIsMobile] = useState(false)
  // const [imagePercentageBigScreen, setImagePercentageBigScreen] = useState(0.75)
  // const [imagePercentageMobile, setImagePercentageMobile] = useState(0.5)
  // const [dataToCrap, setDataToCrap] = useState({
  //   range: 1,
  //   distanceLeft: 0,
  //   distanceTop: 0,
  // })

  // const parentRef = useRef(null)
  // const childRef = useRef(null)

  // const scrollPageDown = (className) => {
  //   const element = document.querySelector(`.${className}`)

  //   if (element) {
  //     element.scrollIntoView({ behavior: 'smooth' })
  //   }
  // }

  // useEffect(() => {
  //   const checkMobile = () => {
  //     const screenWidth = window.innerWidth
  //     const isMobileDevice = screenWidth <= 500

  //     setIsMobile(isMobileDevice)
  //   }

  //   checkMobile()

  //   window.addEventListener('resize', checkMobile)

  //   return () => {
  //     window.removeEventListener('resize', checkMobile)
  //   }
  // }, [])

  // const uploadFile = (event) => {
  //   const file = event.target.files[0]
  //   let time
  //   if (file) {
  //     if (file.type.startsWith('image/')) {
  //       setSelectedImage(file)
  //       const reader = new FileReader()
  //       reader.onload = function (e) {
  //         const img = new Image()
  //         img.onload = function () {
  //           const originalWidth = img.width
  //           setOriginalWidth(originalWidth)
  //           setImgWidth(
  //             isMobile
  //               ? originalWidth * imagePercentageMobile
  //               : originalWidth * imagePercentageBigScreen
  //           )
  //         }
  //         img.src = e.target.result
  //       }
  //       reader.readAsDataURL(file)
  //       // זה בשביל להפעיל שאראה את התמונה ואחתוך אותה לפני שליחה לשרת
  //       setSelectedImageUrl(URL.createObjectURL(file))
  //       event.target.value = null
  //       setTimeout(() => {
  //         scrollPageDown('crap_area')
  //       }, 300)
  //     } else {
  //       setErrorMessage(translations.errorSelectImage)
  //       time = setTimeout(() => {
  //         setErrorMessage('')
  //       }, 2000)
  //     }
  //   } else {
  //     setErrorMessage(translations.errorImage)
  //     time = setTimeout(() => {
  //       setErrorMessage('')
  //     }, 2000)
  //   }
  //   setTimeoutId(time)
  // }

  const sendImg = (event) => {
    const file = event.target.files[0]
    event.target.value = null
    let time
    if (file) {
      if (file.type.startsWith('image/')) {
        setOpenSpinner(true)
        setErrorMessage('')
        const formData = new FormData()
        formData.append('photo', file)
        axiosInstance
          .post('/api/v1/image/removeBG', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            responseType: 'arraybuffer',
          })
          .then(function (response) {
            getAll('analytics/uploadImage').catch((error) => {
              console.log(error)
            })
            setSelectedImage(response.data)
            // setFinishCrap(true)
          })
          .catch(function (error) {
            console.error('Error processing image:', error)
            setOpenSpinner(false)
            setOpenError(true)
          })
      } else {
        setErrorMessage(translations.errorSelectImage)
        time = setTimeout(() => {
          setErrorMessage('')
        }, 2000)
      }
    } else {
      setErrorMessage(translations.errorImage)
      time = setTimeout(() => {
        setErrorMessage('')
      }, 2000)
    }
    setTimeoutId(time)
  }

  useEffect(() => {
    return () => clearTimeout(timeoutId)
  }, [])

  // const handleDragDiv = (event = null, data = null) => {
  //   if (parentRef.current && childRef.current) {
  //     const parentRect = parentRef.current.getBoundingClientRect()
  //     const childRect = childRef.current.getBoundingClientRect()

  //     let distanceLeft
  //     let distanceTop

  //     if (isMobile) {
  //       distanceLeft =
  //         (childRect.left - parentRect.left) / imagePercentageMobile
  //       distanceTop = (childRect.top - parentRect.top) / imagePercentageMobile
  //     } else {
  //       distanceLeft =
  //         (childRect.left - parentRect.left) / imagePercentageBigScreen
  //       distanceTop =
  //         (childRect.top - parentRect.top) / imagePercentageBigScreen
  //     }

  //     if (distanceLeft) {
  //       setDataToCrap((prev) => {
  //         return {
  //           ...prev,
  //           distanceLeft,
  //         }
  //       })
  //     }
  //     if (distanceTop) {
  //       setDataToCrap((prev) => {
  //         return {
  //           ...prev,
  //           distanceTop,
  //         }
  //       })
  //     }
  //   }
  // }

  // const handleDragWidth = (event) => {
  //   const scale = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]

  //   const newScale = scale[event.target.value - 1]
  //   const newWidth = originalWidth * newScale

  //   if (newWidth >= 500) {
  //     setRange(event.target.value)
  //     setDataToCrap((prev) => {
  //       return {
  //         ...prev,
  //         range: newScale,
  //       }
  //     })
  //     setImgWidth(
  //       isMobile
  //         ? newWidth * imagePercentageMobile
  //         : newWidth * imagePercentageBigScreen
  //     )
  //   }
  // }

  return (
    <>
      {openError && (
        <ErrorNotification
          setOpenError={setOpenError}
          errorMessage={
            'There is a problem with image processing. try again in a few minutes or try with a different image'
          }
          duration={5000}
          backgroundColor={'#70c9a1'}
        />
      )}

      <div className={classes.main}>
        <h3 className={classes.upload_h3}>{translations.uploadBtnH3}</h3>

        <label htmlFor="file-upload" className={classes.upload_btn}>
          <img
            src={uploadImg}
            alt="upload Img"
            className={classes.img_upload}
          />
        </label>
        <input
          id="file-upload"
          type="file"
          accept="image/*"
          onChange={sendImg}
          multiple={false}
        />
        {errorMessage && <p className={classes.error}>{errorMessage}</p>}
      </div>
      {/* <div className="crap_area"></div>
      {selectedImageUrl && !finishCrap && (
        <div>
          <div className={classes.crap_all}>
            <input
              className={classes.range}
              type="range"
              onChange={handleDragWidth}
              min="1"
              max="8"
              value={range}
            />
            <button
              onClick={() => {
                handleDragDiv()
                sendImg()
              }}
            >
              <img src={v} alt="v" />
            </button>
          </div>
          <div className={classes.crap_DIY_bg}>
            <div></div>
            <div className={classes.crap_DIY_area} ref={parentRef}>
              <img
                className={classes.crap_DIY_img}
                src={selectedImageUrl}
                alt="img"
                style={{ width: `${imgWidth}px` }}
              />
              <Draggable onDrag={handleDragDiv} bounds="parent">
                <div
                  className={`${classes.draggable_div} ${
                    isMobile && classes.draggable_div_mobile
                  }`}
                  ref={childRef}
                >
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </Draggable>
            </div>
          </div>
        </div>
      )} */}
      {openSpinner && (
        <div className={classes.spinner}>
          <BeatLoader color="#4759f5" size={50} />
        </div>
      )}
      {selectedImage && (
        <ImageProcessing
          imageFile={selectedImage}
          setCanvas={setCanvas}
          setDownloadLinkVisible={setDownloadLinkVisible}
          userChoice={userChoice}
          setOpenSpinner={setOpenSpinner}
          setOriginalImage={setOriginalImage}
          // dataToCrap={dataToCrap}
        />
      )}
    </>
  )
}

export default ClickUpload
