import { useState } from 'react'
import { useApi } from '../customHooks/api'
import axiosInstance from '../axios'
import BeatLoader from 'react-spinners/BeatLoader'

import ErrorNotification from '../component/popUp/ErrorNotification '
import classes from './DisplayResult.module.css'

import imgDownloadHe from '../assets/button_הורד את הקובץ.png'
import imgDownloadEn from '../assets/button_Looks awesome.png'

import imgTryAgainHe from '../assets/button_אנסה שוב.png'
import imgTryAgainEn from '../assets/button_I’ll try again.png'
import spin from '../assets/spin.png'

function DisplayResult({
  translations,
  canvas,
  scrollPageDown,
  setUserChoice,
  setCanvas,
  setOpenUploadBtn,
  setDownloadLinkVisible,
  isHe,
  setFinish,
  finish,
  originalImageGlobal,
  userChoice,
}) {
  const [openError, setOpenError] = useState(false)
  const [openSpinner, setOpenSpinner] = useState(false)
  const { getAll } = useApi()

  const handleSaveClick = () => {
    getAll('analytics/downloadImage').catch((error) => {
      console.log(error)
    })
    uploadFinishFile()

    const downloadLink = document.createElement('a')
    downloadLink.href = canvas.toDataURL('image/png')
    downloadLink.download = 'processed_image.png'
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)

    // זה ההפשרות הראשונה שעישתי שמשתמש בתג שנמצא למטה מוסלש
    // const downloadLink = document.getElementById('downloadLink')
    // downloadLink.click()
    setFinish(true)
  }

  const uploadFinishFile = () => {
    canvas.toBlob(
      function (blob) {
        const file = new File([blob], 'image.jpg', { type: 'image/jpeg' })

        const formData = new FormData()
        formData.append('photo', file)

        axiosInstance
          .post('/api/v1/image/putPhoto', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            responseType: 'arraybuffer',
          })
          .then((response) => {})
          .catch((error) => {})
      },
      'image/jpeg',
      0.95
    )
  }

  const tryAgain = () => {
    setUserChoice({
      quote: 0,
      frame: 0,
      name: '',
      title: '',
    })
    setCanvas(null)
    setOpenUploadBtn(false)
    setDownloadLinkVisible(false)
    scrollPageDown('second_part')
  }

  // /////////////////////
  const [spins, setSpins] = useState(1)

  const rotateImage = async () => {
    setOpenSpinner(true)
    const images = originalImageGlobal.images
    const imageDataUrl = `data:image/png;base64,${originalImageGlobal.base64Image}`
    const originalImage = new Image()
    originalImage.src = imageDataUrl

    const tempCanvas = document.createElement('canvas')
    const tempContext = tempCanvas.getContext('2d')

    originalImage.onload = function () {
      tempCanvas.width = originalImage.height // Swap width and height for rotated image
      tempCanvas.height = originalImage.width

      const totalRotation = (90 * spins * Math.PI) / 180

      tempContext.translate(tempCanvas.width / 2, tempCanvas.height / 2)
      tempContext.rotate(totalRotation)
      tempContext.drawImage(
        originalImage,
        -tempCanvas.width / 2,
        -tempCanvas.height / 2,
        tempCanvas.width,
        tempCanvas.height
      )

      tempContext.setTransform(1, 0, 0, 1, 0, 0)

      const rotatedImageData = tempContext.getImageData(
        0,
        0,
        tempCanvas.width,
        tempCanvas.height
      )
      const rotatedData = rotatedImageData.data

      for (let i = 0; i < rotatedData.length; i += 4) {
        const grayscale =
          rotatedData[i] * 0.299 +
          rotatedData[i + 1] * 0.587 +
          rotatedData[i + 2] * 0.114
        rotatedData[i] = grayscale
        rotatedData[i + 1] = grayscale
        rotatedData[i + 2] = grayscale
      }

      // Continue with the rest of the processing using rotatedImageData
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      canvas.width = 2000
      canvas.height = 2000

      context.putImageData(rotatedImageData, 0, 0)

      // Add background image
      const bg = new Image()
      bg.src = images[0]
      bg.onload = function () {
        context.globalCompositeOperation = 'destination-over'
        context.drawImage(bg, 0, 0, canvas.width, canvas.height)

        // Add front banner image
        const front = new Image()
        front.src = images[1]
        front.onload = function () {
          context.globalCompositeOperation = 'source-over'
          context.drawImage(front, 0, 0, canvas.width, canvas.height)

          // Add text on the middle right side
          context.font = 'bold 90px englishRegular'
          context.fillStyle = 'black'
          context.fillText(
            `${userChoice.name}`,
            canvas.width * 0.53,
            canvas.height * 0.64
          )
          context.font = '90px englishRegular'
          context.fillText(
            `${userChoice.title}`,
            canvas.width * 0.53,
            canvas.height * 0.69
          )
          setCanvas(canvas)
          setSpins((pre) => pre + 1)
          setOpenSpinner(false)
        }
      }
    }
  }

  return (
    <>
      {openError && <ErrorNotification setOpenError={setOpenError} />}
      <div className={classes.main}>
        {!finish && (
          <div>
            <h3 className={classes.title}>{translations.soooWowDid}</h3>
            <div dir="ltr">
              {canvas && (
                <div className={classes.displayImgArea}>
                  <button className={classes.btns} onClick={handleSaveClick}>
                    <img
                      className={classes.btnImg}
                      src={isHe ? imgDownloadHe : imgDownloadEn}
                      alt="Download"
                    />
                  </button>
                  <div className={classes.displayDiv}>
                    {openSpinner && (
                      <div className={classes.spinner_container}>
                        <BeatLoader color="#4759f5" size={50} />
                      </div>
                    )}

                    <img
                      src={canvas.toDataURL('image/png')}
                      className={classes.img}
                      alt="img"
                    />

                    <img
                      className={classes.spin}
                      onClick={rotateImage}
                      src={spin}
                      alt="spin"
                    />
                  </div>
                  <button className={classes.btns} onClick={tryAgain}>
                    <img
                      className={classes.btnImg}
                      src={isHe ? imgTryAgainHe : imgTryAgainEn}
                      alt="Try again"
                    />
                  </button>
                </div>
              )}
              {/* <button
                className={classes.downloadLink}
                id="downloadLink"
                style={{ display: 'block', visibility: 'hidden' }}
                href={canvas?.toDataURL('image/png')}
                download="processed_image.png"
               
              >
                Download Image
              </button> */}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default DisplayResult
