import { useState, useEffect } from 'react'
import { useApi } from './customHooks/api'

import LanguageButton from './component/btn/LanguageButton'
import QuoteSelection from './component/QuoteSelection'
import FrameSelection from './component/FrameSelection'
import ClickUpload from './component/ClickUpload'
import DisplayResult from './component/DisplayResult'
import translationsEn from './Language/eng'
import translationsHe from './Language/he'
import Policy from './component/popUp/Policy'
import Bg from './component/popUp/Bg'

import header from './assets/header.png'
import imgThank from './assets/thankYou.png'
import axiosInstance from './axios'

import checkboxImgHe from './assets/button_אני מאשר.png'
import checkboxImgCleanHe from './assets/button_אני מאשר_cleanup.png'
import checkboxImgEn from './assets/button_Agree to Terms.png'
import checkboxImgCleanEn from './assets/button_Agree to Terms_cleanup.png'

import classes from './HomePage.module.css'

function HomePage() {
  const [isHe, setIsHe] = useState(true)
  const [ischeck, setIscheck] = useState(false)
  const [openUploadBtn, setOpenUploadBtn] = useState(false)
  const [userChoice, setUserChoice] = useState({
    quote: '',
    frame: 0,
    name: '',
    title: '',
  })
  const [canvas, setCanvas] = useState(null)
  const [downloadLinkVisible, setDownloadLinkVisible] = useState(false)
  const [finish, setFinish] = useState(false)
  const [openPopup, setOpenPopuph] = useState(false)
  const [originalImage, setOriginalImage] = useState()

  const { getAll } = useApi()

  const translations = isHe ? translationsHe : translationsEn

  useEffect(() => {
    const hasUpdateBeenSent = sessionStorage.getItem('updateSent')

    if (!hasUpdateBeenSent) {
      getAll('analytics/visits')
        .then((Response) => {
          sessionStorage.setItem('updateSent', 'true')
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [])

  const scrollPageDown = (className) => {
    const element = document.querySelector(`.${className}`)

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const clickedOkToInput = () => {
    if (userChoice?.frame) {
      setOpenUploadBtn(true)
    }
  }

  useEffect(() => {
    if (ischeck) {
      scrollPageDown('second_part')
    }
  }, [ischeck])

  useEffect(() => {
    if (userChoice.quote) {
      scrollPageDown('third_part')
    }
  }, [userChoice.quote])

  useEffect(() => {
    if (openUploadBtn && userChoice.frame) {
      scrollPageDown('four_part')
    }
  }, [openUploadBtn, userChoice.frame])

  useEffect(() => {
    if (downloadLinkVisible) {
      scrollPageDown('fifth_part')
    }
  }, [downloadLinkVisible])

  const readPolicy = () => {
    if (!ischeck) {
      setOpenPopuph(!openPopup)
    }
  }

  const clickedCheck = () => {
    setIscheck(true)
    setOpenPopuph(!openPopup)
  }

  const downloadedImageAgain = () => {
    const downloadLink = document.createElement('a')
    console.log(canvas)
    downloadLink.href = canvas.toDataURL('image/png')
    downloadLink.download = 'processed_image.png'
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  return (
    <>
      <canvas id="canvas" ref={setCanvas} style={{ display: 'none' }} />
      {openPopup && <Bg setOpenPopuph={setOpenPopuph} />}
      {openPopup && (
        <Policy
          setOpenPopuph={setOpenPopuph}
          clickedCheck={clickedCheck}
          translations={translations}
          isHe={isHe}
        />
      )}
      {!finish && (
        <div
          className={`${classes.main} ${
            isHe ? classes.hebrew_font : classes.english_font
          }`}
          dir={isHe ? 'rtl' : 'ltr'}
        >
          <header className={classes.header} dir="ltr">
            <LanguageButton
              className={classes.langBtn}
              setIsHe={setIsHe}
              isHe={isHe}
            />
            <img
              className={classes.img_header}
              src={header}
              alt="header"
              onClick={readPolicy}
            />
          </header>
          <div className={classes.center} dir="ltr" onClick={readPolicy}>
            <div className={`${classes.const_box} ${classes.english_font}`}>
              <p>
                "A culture of diversity and inclusion is part of what defines us
                as a company.”
              </p>
              <div>
                <p>Enrique Lores,</p>
                <p>President and CEO, HP</p>
              </div>
            </div>
            <div
              dir={isHe ? 'rtl' : 'ltr'}
              className={`${classes.boxes_area} ${
                isHe ? classes.hebrew_font : classes.english_font
              }`}
            >
              <div className={classes.first_box}>
                <p>{translations.openingP}</p>
                {isHe && <p>{translations.secondP}</p>}
              </div>
              <div
                className={`${classes.seond_box} ${
                  !isHe && classes.small_letter
                }`}
              >
                <p>{translations.sedondBox}</p>
                <p>{translations.sedondBox2}</p>
                {!isHe && <p>{translations.sedondBox3}</p>}
              </div>
              <div
                className={`${classes.third_box} ${
                  !isHe && classes.small_letter
                }`}
              >
                <p>{translations.thirdBox}</p>
                <p>{translations.thirdBox2}</p>
                {!isHe && <p>{translations.thirdBox3}</p>}
              </div>
            </div>
          </div>
          <div className={classes.checkbox_container} onClick={readPolicy}>
            <img
              className={classes.checkbox_img}
              src={
                isHe && !ischeck
                  ? checkboxImgCleanHe
                  : isHe && ischeck
                  ? checkboxImgHe
                  : !isHe && !ischeck
                  ? checkboxImgCleanEn
                  : checkboxImgEn
              }
              alt="checkbox Img"
            />
          </div>

          <div className="second_part"></div>
          {ischeck && (
            <QuoteSelection
              translations={translations}
              setUserChoice={setUserChoice}
              userChoice={userChoice}
              isHe={isHe}
            />
          )}
          <div className="third_part"></div>
          {userChoice.quote && (
            <FrameSelection
              translations={translations}
              setUserChoice={setUserChoice}
              userChoice={userChoice}
              clickedOkToInput={clickedOkToInput}
              isHe={isHe}
              scrollPageDown={scrollPageDown}
            />
          )}
          <div className="four_part"></div>
          {openUploadBtn && (
            <ClickUpload
              translations={translations}
              setCanvas={setCanvas}
              setDownloadLinkVisible={setDownloadLinkVisible}
              userChoice={userChoice}
              setOriginalImage={setOriginalImage}
            />
          )}
          <div className="fifth_part"></div>
          {downloadLinkVisible && (
            <DisplayResult
              translations={translations}
              canvas={canvas}
              scrollPageDown={scrollPageDown}
              setUserChoice={setUserChoice}
              setCanvas={setCanvas}
              setOpenUploadBtn={setOpenUploadBtn}
              setDownloadLinkVisible={setDownloadLinkVisible}
              isHe={isHe}
              setFinish={setFinish}
              finish={finish}
              originalImageGlobal={originalImage}
              userChoice={userChoice}
            />
          )}
        </div>
      )}
      {finish && (
        <div className={classes.main_img_finish}>
          <img src={imgThank} className={classes.imgThank} alt="Thank you" />
          <div
            onClick={downloadedImageAgain}
            className={classes.sentence_download}
          >
            {translations.ifNoDownload}
          </div>
        </div>
      )}
    </>
  )
}

export default HomePage
